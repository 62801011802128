import { HeaderWrapper } from './HeaderStyle';
import { ReactComponent as LogoImage } from 'assets/logos/mainLogo.svg';
import { ReactComponent as LogoText } from 'assets/logos/logoText.svg';

const Header = () => {
  return (
    <HeaderWrapper>
      <LogoImage />
      <LogoText />
    </HeaderWrapper>
  );
};

export default Header;
