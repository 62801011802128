import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  padding: 20px;
  max-width: 1024px;
  margin: 0 auto;

  @media (max-width: 500px) {
    padding: 20px 5px;
  }
`;