import { ReactComponent as PanelIcon } from 'assets/icons/laptopIcon.svg';
import Panel from 'shared/components/panel/Panel';

const InterationInfo = () => {
  return (
    <Panel
      Icon={PanelIcon}
      headerText={'Channel upgrade'}
      content="InteractionInfoComponent"
      isInitiallyOpen={true}
    />
  );
};

export default InterationInfo;
