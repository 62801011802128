import { ReactComponent as PanelIcon } from 'assets/icons/calendarIcon.svg';
import Panel from 'shared/components/panel/Panel';

const History = () => {
  return (
    <Panel
      Icon={PanelIcon}
      headerText={'Past user activity'}
      content="HistoryComponent"
      isInitiallyOpen={true}
    />
  );
};

export default History;
