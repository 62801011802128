import { ReactComponent as PanelIcon } from 'assets/icons/laptopIcon.svg';
import Panel from 'shared/components/panel/Panel';

const TechnicalSpecs = () => {
  return (
    <Panel
      Icon={PanelIcon}
      headerText={'Technical Specifications'}
      content="TechnicalComponent"
      isInitiallyOpen={false}
    />
  );
};

export default TechnicalSpecs;
