import { ReactComponent as PanelIcon } from 'assets/icons/laptopIcon.svg';
import Panel from 'shared/components/panel/Panel';


const Video = () => {
    return (
      <Panel
        Icon={PanelIcon}
        headerText={'Video'}
        content="VideoComponent"
        isInitiallyOpen={true}
      />
    );
  };
  
  export default Video;
  