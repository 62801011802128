import { ReactComponent as PanelIcon } from 'assets/icons/laptopIcon.svg';
import Panel from 'shared/components/panel/Panel';

const VisitorData = () => {
    return (
        <Panel
            Icon={PanelIcon}
            headerText={'Visitor Data'}
            content="VisitorDataComponent"
            isInitiallyOpen={true}
        />
    );
};

export default VisitorData;