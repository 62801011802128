import styled from 'styled-components';
import colors from 'styles/Variables/colors';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${colors.advancedPalette.blue};
  width: 100%;
  height: 70px;
`;
