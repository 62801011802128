const colors = {
  primary: 'rgb(0, 184, 170)',
  primaryHover: 'rgba(0, 184, 170, 0.20)',
  primaryActive: 'rgba(0, 184, 170, 0.20)',
  secondary: 'rgb(255, 100, 40)',
  secondaryHover: 'rgba(255, 100, 40, 0.20)',
  secondaryActive: 'rgba(255, 100, 40, 0.20)',
  textPrimary: 'rgba(39, 63, 103, 1)',
  textSecondary: 'rgba(84, 86, 90, 1)',
  advancedPalette:{
    blue:'rgba(61, 90, 245, 1)',
    borderDark:'rgb(0,0,0)',
    borderLight:'rgba(158, 159, 162, 0.64)',
    backgroundDark:'rgba(84, 86, 90, 0.12)',
    backgroundLight:'rgba(158, 159, 162, 0.64)'
  }
};

export default colors;
