
const Health = () => {
  return (
    <div>
      <h1>Health</h1>
    </div>
  );
};

export default Health;
