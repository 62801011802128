import styled, { css } from 'styled-components';
import colors from '../Variables/colors';

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 8px 0 rgba(39, 63, 103, 0.08);
  border: 1px solid rgba(158, 159, 162, 0.2);
  border-radius: 8px;
  overflow: hidden;
`;

export const TabContentWrapper = styled.div`
  padding: 0 20px 20px 20px;

  @media (max-width: 500px) {
    padding: 0 5px 20px 5px;
  }
`;

export const PanelCollapse = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 20px;
`;

export const PanelCollapseImage = styled.img``;

export const PanelCollapseText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.textPrimary};
`;

export const PanelCollapseButton = styled.button`
  display: flex;
  gap: 8px;
  margin-left: auto;
  background: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const PanelCollapseButtonText = styled.span`
  color: ${colors.textSecondary};
  font-size: 14px;
  font-weight: 400;
`;

interface PanelCollapseButtonIconProps {
  $active: boolean;
}

export const PanelCollapseButtonIcon = styled.img<PanelCollapseButtonIconProps>`
  ${(props) =>
    props.$active &&
    css`
      rotate: 180deg;
    `}
`;

export const PanelInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span,
  p,
  a {
    align-self: start;
  }

  a {
    text-decoration: none;
    color: ${colors.textPrimary};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  span {
    color: ${colors.textSecondary};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  p {
    color: ${colors.textPrimary};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const getColumnTemplate = (columns: number) => {
  return `grid-template-columns: ${Array(columns).fill('1fr').join(' ')};`;
};
