import { ReactComponent as PanelIcon } from 'assets/icons/personIcon.svg';
import Panel from 'shared/components/panel/Panel';

const Activity = () => {
  return (
    <Panel
      Icon={PanelIcon}
      headerText={'User activity on the website'}
      content="ActivityComponent"
      isInitiallyOpen={true}
    />
  );
};

export default Activity;
