import { ReactComponent as PanelIcon } from 'assets/icons/locationIcon.svg';
import Panel from 'shared/components/panel/Panel';

const Location = () => {
  return (
    <Panel
      Icon={PanelIcon}
      headerText={'Location'}
      content="LocationComponent"
      isInitiallyOpen={false}
    />
  );
};

export default Location;
